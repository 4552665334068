// import React, { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Img, Text, Line } from "components";

// const Sidebar = ({ className, closeDrawer }) => { // Receive closeDrawer as a prop
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [selectedTab, setSelectedTab] = useState('');

//     useEffect(() => {
//         const currentPath = location.pathname;
//         const defaultTab = menuItems.find(item => currentPath.includes(item.path))?.name || 'Dashboard';
//         setSelectedTab(defaultTab);
//     }, [location.pathname]);

//     const handleTabClick = (path, tabName) => {
//         setSelectedTab(tabName);
//         navigate(path); 
//         if (closeDrawer) closeDrawer(); 
//     };
 
//     const menuItems = [
//         { name: 'Dashboard', icon: 'images/homenew.png', path: '/dashboard' },
         
//         // { name: 'Transactions', icon: 'images/transaction.png', path: '/fttransactions' },
//         // { name: 'Beneficiaries', icon: 'images/beneficiarynew.png', path: '/beneficiary' },
//         // { name: 'Loan Application', icon: 'images/loan.png', path: '/loanapplication' },
//         // { name: 'Investments', icon: 'images/investmentnew.png', path: '/investments' },
//         // { name: 'Requests', icon: 'images/requestnew.png', path: '/request' },
//         // { name: 'Settings', icon: 'images/settingnew.png', path: '/settingpageone' },
//         // { name: 'Support', icon: 'images/customer-service.png', path: '/support' },
//         // { name: 'Add Form', icon: 'images/settingnew.png', path: '/adddynamicform' },
//         // { name: 'Loading Form', icon: 'images/customer-service.png', path: '/getdynamicform' },
//         // { name: 'Dynamic Form', icon: 'images/customer-service.png', path: '/dynamicform' },
//         { name: 'User', icon: 'images/profile.png', path: '/user' },
//         { name: 'Role', icon: 'images/project-manager.png', path: '/role' },
//         { name: 'Transaction Limit', icon: 'images/transaction-limit.png', path: '/transaction-limit' },
//         { name: 'Holiday Maintenance', icon: 'images/calendar.png', path: '/holidaymaintenance' },
//         { name: 'Loyalty Rewards', icon: 'images/reward.png', path: '/loyalty' },



         



//         // { name: 'Accounts', icon: 'images/img_user.svg', path: '/accounts' },
//         // { name: 'Investments', icon: 'images/img_map.svg', path: '/investments' },
//         // { name: 'Credit Cards', icon: 'images/img_file.svg', path: '/creditcards' },
//         // { name: 'Loans', icon: 'images/img_question.svg', path: '/loan' },
//         // { name: 'Services', icon: 'images/img_service1.svg', path: '/services' },
//         // { name: 'My Privileges', icon: 'images/img_lightbulb.svg', path: '/my-privileges' },
//         // { name: 'Table List', icon: 'images/img_lightbulb.svg', path: '/tablelist' },
//         // { name: 'Table List2', icon: 'images/img_settings_25X25.svg', path: '/tablelist1' }
//     ];

//     return (
//         <aside className={className}>
//             <div className="sidebarpadding flex flex-col gap-[50px] items-start justify-start pr-[40px] py-[3vh] h-full w-full pr-[100px]" >
//                 <div className="flex flex-col gap-[23px] justify-start w-full" >
//                     {menuItems.map((item) => (
//                         <div
//                             key={item.name}
//                             className="w-full"
//                             onClick={() => handleTabClick(item.path, item.name)}
//                         >
//                             <div className="relative flex items-center cursor-pointer w-full">
//                                 {selectedTab === item.name && (
//                                     <Line className="bg-indigo_600 h-[60px] rounded-br-[10px] rounded-tr-[10px] w-[6px] absolute left-0" />
//                                 )}
//                                 <Img
//                                     src={item.icon}
//                                     className="h-[25px] ml-[20px] w-[25px]"
//                                     alt={item.name}
//                                 />
//                                 <Text
//                                     className={`font-inter font-medium ml-[20px] text-left w-[auto] ${selectedTab === item.name ? 'text-indigo_600' : 'text-gray-600'
//                                         }`}
//                                     as="h5"
//                                     variant="h5"
//                                 >
//                                     {item.name}
//                                 </Text>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </aside>
//     );
// };

// export default Sidebar;
// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Menu } from "antd";
// import { HomeOutlined, UserOutlined, FileOutlined, TeamOutlined, SettingOutlined, CalendarOutlined, TagOutlined, QuestionCircleOutlined  } from '@ant-design/icons';

// const Sidebar = ({ className, closeDrawer }) => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [selectedTab, setSelectedTab] = useState('');

//     useEffect(() => {
//         const currentPath = location.pathname;
//         const defaultTab = menuItems.find(item => currentPath.includes(item.path))?.name || 'Dashboard';
//         setSelectedTab(defaultTab);
//     }, [location.pathname]);

//     const handleTabClick = (path, tabName) => {
//         setSelectedTab(tabName);
//         navigate(path);
//         if (closeDrawer) closeDrawer();
//     };

//     const menuItems = [
//         { name: 'Dashboard', icon: <HomeOutlined />, path: '/dashboard' },
//         { name: 'User', icon: <UserOutlined />, path: '/user' },
//         { name: 'Role', icon: <TeamOutlined />, path: '/role' },
//         { name: 'Transaction Management', icon: <SettingOutlined />, path: '/transaction-limit' },
//         { name: 'Holiday Maintenance', icon: <CalendarOutlined />, path: '/holidaymaintenance' },
//         { name: 'Loyalty Rewards', icon: <TagOutlined />, path: '/loyalty' },
//         { name: 'Cards Management', icon: <SettingOutlined />, path: '/enabledisablelist' },
//         { name: 'Support', icon: <QuestionCircleOutlined  />, path: '/support' },
//         { name: 'Transaction Report', icon: <FileOutlined  />, path: '' },


//     ];

//     return (
//         <aside className={className} style={{ backgroundColor: '#f0f2f5', height: '', padding: 0 }}> {/* Using Ant Design's default background color */}
//             <Menu
//                 mode="inline"
//                 selectedKeys={[selectedTab]}
//                 style={{ width: 256, height: '100%', border: 'none' }} // Adjust height to fill the sidebar
//                 theme="light"
//             >
//                 {menuItems.map((item) => (
//                     <Menu.Item
//                         key={item.name}
//                         icon={item.icon}
//                         onClick={() => handleTabClick(item.path, item.name)}
//                     >
//                         {item.name}
//                     </Menu.Item>
//                 ))}
//             </Menu>
//         </aside>
//     );
// };

// export default Sidebar;
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { HomeOutlined, UserOutlined, FileOutlined, FundProjectionScreenOutlined,TeamOutlined, SettingOutlined, CalendarOutlined, TagOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const Sidebar = ({ className, closeDrawer }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState('');

    useEffect(() => {
        const currentPath = location.pathname;
        const defaultTab = menuItems.find(item => currentPath.includes(item.path))?.name || 'Dashboard';
        setSelectedTab(defaultTab);
    }, [location.pathname]);

    const handleTabClick = (path, tabName) => {
        setSelectedTab(tabName);
        navigate(path);
        if (closeDrawer) closeDrawer();
    };

    const menuItems = [
        { name: 'Dashboard', icon: <HomeOutlined />, path: '/dashboard' },
        { name: 'User', icon: <UserOutlined />, path: '/user' },
        { name: 'Role', icon: <TeamOutlined />, path: '/role' },
        { name: 'Screen', icon: <FundProjectionScreenOutlined />, path: '/screen' },
        { name: 'Transaction Management', icon: <SettingOutlined />, path: '/transaction-limit' },
        { name: 'Holiday Maintenance', icon: <CalendarOutlined />, path: '/holidaymaintenance' },
        { name: 'Loyalty Rewards', icon: <TagOutlined />, path: '/loyalty' },
        { name: 'Cards Management', icon: <SettingOutlined />, path: '/enabledisablelist' },
        { name: 'Support', icon: <QuestionCircleOutlined />, path: '/support' },
        {
            name: 'Transaction Report',
            icon: <FileOutlined />,
            subMenu: [
                { name: 'Active Customers Report', path: '/customer' },
                { name: 'Within Bank Report', path: '/withinbanktransfer' },
                { name: 'NEFT Report', path: '/neft' },
                { name: 'RTGS Report', path: '/rtgs' },
                { name: 'IMPS Report', path: '/imps' }

            ]
        },
        { name: 'Security Question', icon: <QuestionCircleOutlined />, path: '/security-questions' },
        { name: 'ChequeBook', icon: <TagOutlined/>, path: '/cheque' },
    ];

    return (
        <aside className={className} style={{ backgroundColor: '#f0f2f5', height: '', padding: 0 }}>
            <Menu
                mode="inline"
                selectedKeys={[selectedTab]}
                style={{ width: 260, height: '100%', border: 'none' }}
                theme="light"
            >
                {menuItems.map((item) => (
                    item.subMenu ? (
                        <Menu.SubMenu key={item.name} icon={item.icon} title={item.name}>
                            {item.subMenu.map(subItem => (
                                <Menu.Item
                                    key={subItem.name}
                                    onClick={() => handleTabClick(subItem.path, subItem.name)}
                                >
                                    {subItem.name}
                                </Menu.Item>
                            ))}
                        </Menu.SubMenu>
                    ) : (
                        <Menu.Item
                            key={item.name}
                            icon={item.icon}
                            onClick={() => handleTabClick(item.path, item.name)}
                        >
                            {item.name}
                        </Menu.Item>
                    )
                ))}
            </Menu>
        </aside>
    );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import { Button, Row, Col, Typography, Drawer } from "antd";
import { Header } from "antd/es/layout/layout";
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Sidebar from './sidebar';
import { useSelector, useDispatch } from 'react-redux';
import { loginDetailsSelector } from "store/selectors/app.selector";
import RegistrationService from "../../services/registration.service";
import config from "../../../src/services/constant";
import { OPERTION_CODE } from "Constants/enum";
import { HomeOutlined, FileOutlined, TeamOutlined } from '@ant-design/icons';
import './topbanner.css'; 

const { Text } = Typography;

// Menu items
const menuItems = [
    { name: 'Loan Dashboard', icon: <HomeOutlined />, path: '/loanMaintenance' },
    // { name: 'Dynamic Form', icon: <FileOutlined />, path: '/adddynamicform' },
    // { name: 'Form Details', icon: <TeamOutlined />, path: '/form-details' },
    { name: 'Add New Loan Prodcut', icon: <FileOutlined />, path: '/addloanproduct' },
    { name: 'Loan Product Details', icon: <TeamOutlined />, path: '/form-details' },
    { name: 'Reject Resaons', icon: <TeamOutlined />, path: '/rejectreasons' },
    { name: 'Processing Status', icon: <TeamOutlined />, path: '/processingstatus' },
    { name: 'Create Team', icon: <TeamOutlined />, path: '/teamlist' },
];

const TopBanner = () => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(menuItems[0].name); // Set initial selected item
    const navigate = useNavigate();

    const showDrawer = () => setVisible(true);
    const closeDrawer = () => setVisible(false);

    const loginDetails = useSelector(loginDetailsSelector);

    const onLogout = () => {
        dispatch({ type: "USER_LOGOUT" });
        navigate("/login");
    };

    const handleMenuClick = (item) => {
        setSelectedItem(item.name); 
        navigate(item.path);
    };

    return (
        <Header className="top-header" style={{ backgroundColor: '#ffffff', padding: '' }}>
            <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                <Col xs={2} sm={0}>
                    <MenuOutlined onClick={showDrawer} style={{ fontSize: '24px', color: '#003366' }} />
                </Col>

                <Col xs={0} sm={14} style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="images/techverve.png" alt="Logo" className="logo-image" />
                    <Text className="welcome-text" style={{ marginLeft: '20px', fontSize: '22px', color: '#003366' }}>
                        Welcome to Techverve Internet Banking
                    </Text>
                </Col>

                <Col xs={22} sm={8}>
                    <Row align="middle" justify="end" gutter={[16, 0]}>
                        <Col className="user-info">
                            <Text strong style={{ fontSize: '16px' }}>{loginDetails.name}</Text>
                            <br />
                            <Text type="secondary" style={{ fontSize: '14px' }}>
                                {loginDetails.lastLogin && new Date(loginDetails.lastLogin).toString().split('GMT')[0].trim()}
                            </Text>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={onLogout}
                                icon={<LogoutOutlined />}
                                style={{ backgroundColor: '#003366', borderColor: '#003366' }}
                            >
                                Logout
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Horizontal Menu Items */}
            <div className="menu-container">
                {menuItems.map(item => (
                    <div
                        key={item.name}
                        className={`menu-item ${selectedItem === item.name ? 'active' : ''}`} 
                        onClick={() => handleMenuClick(item)}
                        style={{ fontSize: '14px', fontWeight:'normal' }}
                    >
                        {item.icon}
                        <span>{item.name}</span>
                    </div>
                ))}
            </div>

            <Drawer
                title="Menu"
                placement="left"
                closable={true}
                onClose={closeDrawer}
                visible={visible}
                bodyStyle={{ padding: 0 }}
                style={{ backgroundColor: '#f0f2f5' }}
            >
                <Sidebar closeDrawer={closeDrawer} />
            </Drawer>
        </Header>
    );
};

export default TopBanner;

import React, { useState } from "react";

const formJson = {
  fields: [
    {
      label: "From",
      name: "from",
      component: "select",
      options: [
        { value: "USA", label: "United States" },
        { value: "Canada", label: "Canada" },
        { value: "Mexico", label: "Mexico" }
      ]
    },
    {
      label: "To",
      name: "to",
      component: "select",
      options: [
        { value: "USA", label: "United States" },
        { value: "Canada", label: "Canada" },
        { value: "Mexico", label: "Mexico" }
      ]
    },
    {
      label: "Amount",
      name: "amount",
      type: "number",
      component: "input",
      currency: "INR"
    },
    {
      label: "Date",
      name: "date",
      type: "date",
      component: "input"
    },
    {
      label: "Set as repeating Transfer",
      name: "repeating",
      component: "checkbox"
    },
    {
      label: "Transfer mode",
      name: "transferMode",
      component: "select",
      options: [
        { value: "bank", label: "Bank Transfer" },
        { value: "gpay", label: "GPay" },
      ]
    },
    {
      label: "Remark",
      name: "remark",
      component: "textarea"
    },
    {
      label: "Notify Payee",
      name: "notifyPayee",
      component: "custom",
      fields: [
        {
          name: "notificationType",
          component: "select",
          options: [
            { value: "mobile", label: "Mobile" },
            { value: "email", label: "Email" }
          ]
        },
        {
          name: "notificationDetails",
          component: "input",
          type: "text"
        }
      ]
    }
  ]
};

export default function DynamicForm2() {
  const [formData, setFormData] = useState({});

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const renderField = (field, index) => {
    const { component, label, name, currency, fields, ...rest } = field;

    switch (component) {
      case "select":
        return (
          <div key={index}>
            <label>{label}</label>
            <select name={name} onChange={handleInputChange} {...rest}>
              <option value="">Select an option</option>
              {field.options.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        );

      case "input":
        return (
          <div key={index}>
            <label>{label}</label>
            {field.type === "number" && (
              <div style={{ position: "relative", display: "inline-block" }}>
                <span
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                    color: "#555",
                  }}
                >
                  ₹
                </span>
                <input
                  name={name}
                  type="number"
                  onChange={handleInputChange}
                  {...rest}
                  style={{
                    paddingLeft: "25px",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                />
              </div>
            )}
            {field.type === "date" && (
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  name={name}
                  type="date"
                  onChange={handleInputChange}
                  {...rest}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                />
              </div>
            )}
            {field.type === "text" && (
              <input
                name={name}
                type="text"
                onChange={handleInputChange}
                {...rest}
              />
            )}
          </div>
        );

      case "checkbox":
        return (
          <div key={index}>
            <label>
              <input
                type="checkbox"
                name={name}
                onChange={handleInputChange}
                checked={formData[name] || false}
              />{" "}
              {label}
            </label>
          </div>
        );

      case "textarea":
        return (
          <div key={index}>
            <label>{label}</label>
            <textarea name={name} onChange={handleInputChange} {...rest} />
          </div>
        );

      case "custom":
        return (
          <div key={index}>
            <label>{label}</label>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {fields.map((subField, subIndex) => {
                return (
                  <div key={subIndex}>
                    {subField.component === "select" ? (
                      <select
                        name={subField.name}
                        onChange={handleInputChange}
                        {...rest}
                      >
                        <option value="">Select an option</option>
                        {subField.options.map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        name={subField.name}
                        type="text"
                        onChange={handleInputChange}
                        {...rest}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const submittedData = {};

    formJson.fields.forEach((field) => {
      if (field.name === "notifyPayee") {

        submittedData[field.name] = {
          notificationType: formData.notificationType || null,
          notificationDetails: formData.notificationDetails || null,
        };
      } else {
        const fieldValue = formData[field.name];
        submittedData[field.name] = fieldValue !== undefined ? fieldValue : null;
      }
    });

    console.log("Submitted Data:", submittedData);
  };

  return (
    <form onSubmit={handleSubmit}>
      {formJson.fields.map((field, index) => renderField(field, index))}
      <button type="submit">Submit</button>
    </form>
  );
}

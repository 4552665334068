import { MaterialReactTable } from "material-react-table";
import React, { useMemo, useState } from "react";
 const data3 = [
    {
      code: "E101",
      name: "Ajay Bhatia",
      salary: 500000,
      phone: 9815215233
    },
    {
      code: "E102",
      name: "Ajit Singh",
      salary: 510000,
      phone: 9415245233
    },
    {
      code: "E103",
      name: "Rameshwer Singh",
      salary: 550000,
      phone: 9912215243
    },
    {
      code: "E104",
      name: "James Smith",
      salary: 200000,
      phone: 9915115131
    },
    {
      code: "E105",
      name: "Kruth",
      salary: 400000,
      phone: 9015115131
    },
    {
      code: "E106",
      name: "Kruth",
      salary: 400000,
      phone: 9015115131
    },
    {
      code: "E105",
      name: "Kruth",
      salary: 400000,
      phone: 9015115131
    },
    {
      code: "E105",
      name: "Kruth",
      salary: 400000,
      phone: 9015115131
    },
    {
      code: "E105",
      name: "Kruth",
      salary: 400000,
      phone: 9015115131
    }
  ];

const TableList2 = () => {
    const [tableData, setTableData] = useState([]);
    const [pageSize, setPageSize] = useState(5);

    const columns = useMemo(() => {
        setTableData(data3)
        console.log(tableData);
        
        if (tableData.length === 0) return [];
        return Object.keys(tableData[0]).map((key) => ({
          accessorKey: key,
          header: key.charAt(0).toUpperCase() + key.slice(1),
        }));
      }, [tableData]);
  
  
  return (
    <MaterialReactTable
      columns={columns}
      data={tableData}
      enablePagination
      enableGlobalFilter
      initialState={{ pagination: { pageSize: pageSize } }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [5, 10, 20],
        onRowsPerPageChange: (e) => setPageSize(parseInt(e.target.value, 10)),
      }}
    />

  );
};

export default TableList2;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { HomeOutlined, UserOutlined, FileOutlined, TeamOutlined, SettingOutlined, CalendarOutlined, TagOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const Sidebar = ({ className, closeDrawer }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState('');

    useEffect(() => {
        const currentPath = location.pathname;
        const defaultTab = menuItems.find(item => currentPath.includes(item.path))?.name || 'Loan Dashboard';
        setSelectedTab(defaultTab);
    }, [location.pathname]);

    const handleTabClick = (path, tabName) => {
        setSelectedTab(tabName);
        navigate(path);
        if (closeDrawer) closeDrawer();
    };

    const menuItems = [
        { name: 'Loan Dashboard', icon: <HomeOutlined />, path: '/loanMaintenance' },
        { name: 'Dynamic Form', icon: <FileOutlined />, path: '/adddynamicform' },
        { name: 'Form Details', icon: <TeamOutlined />, path: '/form-details' },
    ];

    return (
        <aside className={className} style={{ backgroundColor: '#f0f2f5', padding: 0 }}>
            <Menu
                mode="inline"
                selectedKeys={[selectedTab]}
                style={{ width: 200, height: '100%', border: 'none', fontSize: '14px' }} // Decreased width and font size
                theme="light"
            >
                {menuItems.map((item) => (
                    <Menu.Item
                        key={item.name}
                        icon={item.icon}
                        onClick={() => handleTabClick(item.path, item.name)}
                        style={{ fontSize: '14px' }} // Adjust font size for Menu.Item
                    >
                        {item.name}
                    </Menu.Item>
                ))}
            </Menu>
        </aside>
    );
};

export default Sidebar;

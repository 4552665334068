import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authTokenSelector } from "../../store/selectors/auth.selector";
import { useSelector } from "react-redux";
import SideBar from "./sidebar";
import TopBanner from "./topbanner";

export default function LoanLayout({ children }) {
    const authToken = useSelector(authTokenSelector);
    const navigate = useNavigate();

    useEffect(() => {
        // if (authToken === null) {
        //     navigate("/login");
        // }
    }, [authToken, navigate]);

    return (
        <div className="app-container">
            <div className="top-banner">
                <TopBanner />
            </div>
            <div className="content" style={{marginTop:'100px'}}>
                {children}
            </div>
        </div>
    );
}

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import Layout from "pages/layout/layout";
import RouterLoader from "./components/RouterLoader"
import TableList2 from "components/DynamicTable";
import DynamicForm2 from "components/DynamicForm2";
import LoanLayout from "pages/LoanPanel/loanlayout";

const SettingPageThree = React.lazy(() => import("pages/SettingPageThree"));
const SettingPageTwo = React.lazy(() => import("pages/SettingPageTwo"));
const SettingPageOne = React.lazy(() => import("pages/SettingPageOne"));
const Services = React.lazy(() => import("pages/Services"));
const Loan = React.lazy(() => import("pages/Loan"));
const CreditCards = React.lazy(() => import("pages/CreditCards"));
const Investments = React.lazy(() => import("pages/Investments"));
const Accounts = React.lazy(() => import("pages/Accounts"));
const Transaction = React.lazy(() => import("pages/Transaction"));
const MainDashboard = React.lazy(() => import("pages/MainDashboard"));
const Login = React.lazy(() => import("pages/Login/index"));
const Beneficiary = React.lazy(() => import("pages/Beneficiary"));
const AddBeneficiary = React.lazy(() => import("pages/Beneficiary/addBeneficiary"));
const DynamicForm = React.lazy(() => import("pages/DynamicForm"));
const Registration = React.lazy(() => import("pages/Registration"));
const Transactions = React.lazy(() => import("pages/Transaction/createtranaction"));
const SelfTransfers = React.lazy(() => import("pages/Transaction/selfTransfer"))
const Statement = React.lazy(() => import("pages/Statement"));
const LocationAtmBranch = React.lazy(() => import("pages/LocationAtmBranch"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const NeftTransfer = React.lazy(() => import("pages/FTTransactions/neft"))
const FAQs = React.lazy(() => import("pages/FQAs"));
const RtgsTransfer = React.lazy(() => import("pages/FTTransactions/rtgs"));
const ImpsTransfer =React.lazy(() => import("pages/FTTransactions/imps"))
const LoanApplication = React.lazy(() => import("pages/Loans/loanapplication"));
const ScheduledTransfers = React.lazy(() => import("pages/Transaction/scheduleTransfer"))
const DashboardV2 = React.lazy(() => import("pages/Dashboard/dashboardv2"));
const TransactionHistory = React.lazy(() => import("pages/Transaction/transactionsHistory"))
const Kyc = React.lazy(() => import("pages/Kyc"))
const Support = React.lazy(() => import("pages/support"))
const Request = React.lazy(() => import("pages/cheque"))
const Calculator = React.lazy(() => import("pages/Calculator"))
const Cheque = React.lazy(()=> import("pages/cheque"));
const Changepassword = React.lazy(()=> import("pages/changepassword"));
// const Transationlimit = React.lazy(()=> import("pages/transactionlimit"));
const LoanApp = React.lazy(()=> import("pages/Loans/allloanApplication"));
const TermAndCondtion = React.lazy(()=> import("pages/TermAndCondtion"));
const ViewFdRate = React.lazy(()=> import("pages/Investments/viewfdrate"));
const BranchSearchPage =React.lazy(()=> import("pages/Beneficiary/branchSearch"))
const NewBeneficiaryModal = React.lazy(()=>import("pages/Beneficiary/newBeneficiary"))
const Transactionlimit = React.lazy(()=>import("pages/Transaction-limit"))
const ForgotPassword = React.lazy(()=>import("pages/ForgotPassword"))
const Withinbanktransfer = React.lazy(()=>import("pages/FTTransactions/withinbanktransfer"))
const FTTransaction = React.lazy(() => import("pages/FTTransactions"))
const AddDynamicFormField = React.lazy(()=>import("pages/DynamicJson/dyamicform"))
const GetDynamicForm = React.lazy(()=>import("pages/DynamicJson/getdynamicform"))
const TermandCondition = React.lazy(() => import("pages/TermAndCondtion/terms&Condition"))
const Privacy = React.lazy(() => import("pages/TermAndCondtion/privecy&Plolicy"))
const User = React.lazy(() => import("pages/User/userlist"))
const CreateUser = React.lazy(() => import("pages/User/createuser"))
const Role = React.lazy(() => import("pages/Role/rolelist"))
const CreateRole = React.lazy(() => import("pages/Role/createrole"))
const Customer = React.lazy(() => import("pages/Customer"))

// const Privacy = React.lazy(() => import("pages/TermAndCondtion/privecy&Plolicy"))
const HolidayMaintenance = React.lazy(() => import("pages/HolidayMaintenance"))
const Layalty = React.lazy(() => import("pages/LoyaltyScreen"))
const CardEnabledisable = React.lazy(() => import("pages/Cards/cardslist"))
const Cards = React.lazy(() => import("pages/Cards/index"))
const LoanMaintenancePage = React.lazy(() => import("pages/LoanMaintenance"))
const LoanDetailPage = React.lazy(() => import("pages/LoanMaintenance/loanDetailsPage"))
const UpdateRole = React.lazy(() => import("pages/Role/updaterole"))
const UpdateUser = React.lazy(() => import("pages/User/updateuser"))
const FormDetails = React.lazy(() => import("pages/DynamicJson/formDetails"))
const ModuleDetail = React.lazy(()=> import("pages/DynamicJson/moduleDetails"))
const RejectReasons = React.lazy(()=> import("pages/RejectResons"))

const AddLoanProduct = React.lazy(()=> import("pages/DynamicJson/AddLoanProduct"))
const ProcessingStatus = React.lazy(()=> import("pages/ProcessingStatus"))
const ViewLoanProdcutDetails = React.lazy(()=> import("pages/DynamicJson/ViewLoanProdcutDetails"))
const ViewLoanProductFields = React.lazy(()=> import("pages/DynamicJson/ViewLoanProductFields"))
const TeamsList = React.lazy(()=> import("pages/CreateTeam/index"))
const CreateTeam = React.lazy(()=> import("pages/CreateTeam/createteam"))
const UpdateTeam = React.lazy(()=> import("pages/CreateTeam/updateteam"))
const TransactionlimitList = React.lazy(()=> import("pages/Transaction-limit/transactionlimitlist"))
const ScreenList = React.lazy(()=> import("pages/Screen/screenlist"))
const ScreenCreate = React.lazy(()=> import("pages/Screen/addscreen"))
const ScreenUpdate = React.lazy(()=> import("pages/Screen/updatescreen"))
const SecurityQuestion = React.lazy(()=> import("pages/SecurityQuestions"))
const CreateChequeBook = React.lazy(()=> import("pages/cheque/createchequebook"))







const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<RouterLoader />}> 
      <Router>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/maindashboard" element={<MainDashboard />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/investments" element={<Layout><Investments /></Layout>} />
          <Route path="/creditcards" element={<CreditCards />} />
          <Route path="/loan" element={<Loan />} />
          <Route path="/services" element={<Services />} />
          <Route path="/settingpageone" element={<Layout><SettingPageOne /></Layout>} />
          <Route path="/settingpagetwo" element={<SettingPageTwo />} />
          <Route path="/settingpagethree" element={<SettingPageThree />} />
          <Route path="/beneficiary" element={<Layout><Beneficiary /></Layout>} />
          {/* <Route path="/dynamicform" element={<DynamicForm />} /> */}
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/addbeneficiary" element={<AddBeneficiary />} />
          <Route path="/createtransaction" element={<Layout><Transactions /></Layout>} />
          <Route path="/selftranfer" element={<SelfTransfers />} />
          <Route path="/statement/:accountNo" element={<Layout><Statement /></Layout>} />
          <Route path="/locationatmbranch" element={<LocationAtmBranch />} />
          <Route path="/neft" element={<Layout><NeftTransfer /></Layout>} />
          {/* <Route path="/dashboard" element={<Layout>  <Dashboard /></Layout>} /> */}
          <Route path="/faqs" element={<FAQs />} />
          {/* <Route path="/rtgs" element={<RtgsTransfer />} /> */}
          <Route path="/imps" element={<Layout><ImpsTransfer /></Layout>} />
          <Route path="/rtgs" element={<Layout><RtgsTransfer /></Layout>} />
          <Route path="/loanapplication" element={<Layout><LoanApplication /></Layout>} />
          <Route path="/scheduletransfers" element={<ScheduledTransfers />} />
          <Route path="/dashboard" element={<Layout><DashboardV2 /></Layout>} />
          <Route path="/transactinshistory" element={<TransactionHistory />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/support" element={<Layout><Support /></Layout>} />
          <Route path="/request" element={<Layout><Request /></Layout>} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/cheque" element={<Layout> <Cheque/> </Layout>}/> 
           <Route path="/changepassword" element={<Layout> <Changepassword/> </Layout>}/> 
           {/* <Route path="/transactionlimit" element={<Layout> <Transationlimit/> </Layout>}/> */}
           <Route path="/loanapp" element={ <LoanApp/>}/>
           <Route path="/termandcondition" element={ <TermAndCondtion/>}/>
           <Route path="/viewfdrate" element={ <ViewFdRate/> }/>
          <Route path="/branchSearch" element={<BranchSearchPage/>}/>
          <Route path="/newbeneficiary" element={<Layout><NewBeneficiaryModal/></Layout>}/>
          <Route path="/transaction-limit" element={<Layout><Transactionlimit/></Layout>}/>
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="/withinbanktransfer" element={<Layout><Withinbanktransfer/></Layout>}/>
          <Route path="/getdynamicform" element={<Layout><GetDynamicForm/></Layout>}/>
          <Route path="/adddynamicform" element={<LoanLayout><AddDynamicFormField /></LoanLayout>} />
          <Route path="/fttransactions" element={<Layout><FTTransaction /></Layout>} />
          <Route path="/dynamictable" element={<Layout><TableList2 /></Layout>} />
          <Route path="/dynamicform" element={<Layout><DynamicForm2 /></Layout>} />
          <Route path="/terms" element={<TermandCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/user" element={<Layout><User/></Layout>} />
          <Route path="/createuser" element={<Layout><CreateUser/></Layout>} />
          <Route path="/role" element={<Layout><Role/></Layout>} />
          <Route path="/createrole" element={<Layout><CreateRole/></Layout>} />
          <Route path="/customer" element={<Layout><Customer/></Layout>} />
          <Route path="/holidaymaintenance" element={<Layout><HolidayMaintenance/></Layout>} />
          <Route path="/loyalty" element={<Layout><Layalty/></Layout>} />
          <Route path="//enabledisable" element={<Layout><Cards/></Layout>} />
          <Route path="/enabledisablelist" element={<Layout><CardEnabledisable/></Layout>} />
          <Route path="/loanMaintenance" element={<LoanLayout><LoanMaintenancePage/></LoanLayout>} />
          <Route path="/loan-details/:applicationId" element={ <LoanLayout><LoanDetailPage/></LoanLayout>} />
          <Route path="/updaterole/:id" element={<Layout><UpdateRole/></Layout>} />
          <Route path="/userupdate/:id" element={<Layout><UpdateUser/></Layout>} />
          <Route path="/form-details" element={<LoanLayout><FormDetails/></LoanLayout>} />
          <Route path="/details" element={<LoanLayout><ModuleDetail/></LoanLayout>} />
          <Route path="/addloanproduct" element={<LoanLayout><AddLoanProduct/></LoanLayout>} />
          <Route path="/rejectreasons" element={<LoanLayout><RejectReasons/></LoanLayout>} />
          <Route path="/processingstatus" element={<LoanLayout><ProcessingStatus/></LoanLayout>} />
          <Route path="/viewloanproductdetails" element={<LoanLayout><ViewLoanProdcutDetails/></LoanLayout>} />
          <Route path="/viewloanproductfielddetails" element={<LoanLayout><ViewLoanProductFields/></LoanLayout>} />
          <Route path="/teamlist" element={<LoanLayout><TeamsList/></LoanLayout>} />
          <Route path="/createteam" element={<LoanLayout><CreateTeam/></LoanLayout>} />
          <Route path="/teamupdate/:id" element={<LoanLayout><UpdateTeam/></LoanLayout>} />
          <Route path="/transactionlimitlist" element={<Layout><TransactionlimitList/></Layout>} />
          <Route path="/screen" element={<Layout><ScreenList/></Layout>} />
          <Route path="/createscreen" element={<Layout><ScreenCreate/></Layout>} />
          <Route path="/updatescreen/:id" element={<Layout><ScreenUpdate/></Layout>} />
          <Route path="/security-questions" element={<Layout><SecurityQuestion/></Layout>} />
          <Route path="/createchequebook/:id" element={<Layout><CreateChequeBook/></Layout>} />



          
        </Routes>
      </Router>
    </React.Suspense>
  );  
};
export default ProjectRoutes;
